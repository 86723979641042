<template>
  <div class="section">
    <div class="content">
      <div class="title">
        <span></span>
        二十大
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
@import url('../../../static/css/home.less');
@import url('../../../static/css/media.less');

.section {
  width: 100%;
  height: 100vh;
  background-image: url('../../../static/images/about/partyBuilding.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.content {
  position: absolute;
  width: 80%;
  height: 45%;
  background: #fff;
  top: 45%;
  left: 10%;
}

.content .title {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 20px 0 0 20px;
}

.content .title span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #646464;
  margin-right: 10px;
}

.content .line {
  width: 100%;
  height: 1px;
  background: #646464;
  margin: 20px 0;
}
</style>